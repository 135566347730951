var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.country
    ? _c(
        "div",
        [
          _c("validation-observer", {
            ref: "form",
            scopedSlots: _vm._u(
              [
                {
                  key: "default",
                  fn: function (ref) {
                    var handleSubmit = ref.handleSubmit
                    return [
                      _c(
                        "v-form",
                        {
                          on: {
                            submit: function ($event) {
                              $event.stopPropagation()
                              $event.preventDefault()
                              return handleSubmit(_vm.onSubmit)
                            },
                          },
                        },
                        [
                          _c(
                            "v-card",
                            { attrs: { outlined: "" } },
                            [
                              _vm.country.id
                                ? _c("v-card-title", [
                                    _vm._v(
                                      "Editing " +
                                        _vm._s(_vm.translate(_vm.country.name))
                                    ),
                                  ])
                                : _c("v-card-title", [
                                    _vm._v("Creating new country"),
                                  ]),
                              _c(
                                "v-card-text",
                                [
                                  _vm._v(" TODO: Missing region "),
                                  _c("m-field", {
                                    attrs: { m: "country", a: "code" },
                                    model: {
                                      value: _vm.country.code,
                                      callback: function ($$v) {
                                        _vm.$set(_vm.country, "code", $$v)
                                      },
                                      expression: "country.code",
                                    },
                                  }),
                                  _c("m-field", {
                                    attrs: {
                                      m: "country",
                                      a: "name",
                                      i18n: "",
                                    },
                                    model: {
                                      value: _vm.country.name,
                                      callback: function ($$v) {
                                        _vm.$set(_vm.country, "name", $$v)
                                      },
                                      expression: "country.name",
                                    },
                                  }),
                                ],
                                1
                              ),
                              _c(
                                "v-card-actions",
                                [
                                  _c("v-spacer"),
                                  _c(
                                    "v-btn",
                                    {
                                      attrs: {
                                        type: "submit",
                                        color: "primary",
                                      },
                                    },
                                    [_vm._v(_vm._s(_vm.$t("actions.save")))]
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ]
                  },
                },
              ],
              null,
              false,
              3732880806
            ),
          }),
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }